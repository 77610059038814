import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    hidden: true,
    component: () => import("@/views/login.vue")
  },
  {
    path: '/home',
    component:() => import("@/views/home.vue"),
    children: [{
      path: '/chat/chatUs',
      component: () => import("@/views/chat/chatUs.vue"),
    }, {
      path: '/list/showList',
      component: () => import("@/views/list/showList.vue"),
    },
    {
      path: '/list/category',
      component: () => import("@/views/list/showCategory.vue"),
    },
    {
      path: '/list/detail',
      component: () => import("@/views/list/detailData.vue"),
    },
    {
      path: '/list/ceramictile',
      component: () => import("@/views/list/ceramicTile.vue"),
    },
    {
      path: '/list/demodispaly',
      component: () => import("@/views/list/demoDisplay.vue"),
    },
    {
      path: '/list/democategory',
      component: () => import("@/views/list/demoCategory.vue"),
    },
    {
      path: '/list/swipershow',
      component: () => import("@/views/list/swiperShow.vue"),
    },
    {
      path: '/list/showvideo',
      component: () => import("@/views/list/showVideo.vue"),
    }
    ]
  }

];

const createRouter = () =>
  new VueRouter({
    // mode: "history",
    routes: routes
  });

const router = createRouter();


export default router;
